import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const MountBlanc = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Mount Blanc",
        href: slug,
        lang: "pl",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #A08C55, #AE985B, #BBA360, #AE985B, #A08C55 )",
        icons: "#c5af71",
        navClass: "mountblanc",
        ogImage: require("../../assets/img/portfolio/mb_top_back.jpg"),
      }}
    >
      <PortfolioHeader name="mb" height="240" />
      <section className="container-fluid mb_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Mount Blanc</h1>
              <ul>
                <li>Projekt katalogów</li>
                <li>Sesja zdjęciowa</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                Nasza współpraca z firmą Mount Blanc zaczęła się od audytu
                dotychczasowych materiałów oraz ustalenia, że materiały to są na
                za niskim poziomie w stosunku do tego w jaki sposób marka chce
                prezentować swoje produkty oraz jaki poziom wyznaczała już
                konkurencja. Ustaliliśmy kierunek działania i stworzyliśmy
                całkowicie nową koncepcję katalogów sprzedażowych prezentującą
                ofertę Mount Blanc. Na przestrzeni całej naszej współpracy
                wykonaliśmy ponad 1000 zdjęć oraz stworzyliśmy trzy katalogi.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid mb_section_3">
        <div className="row">
          <div className="col-md-6 offset-md-1">
            <div className="inner">
              <h3>Projekty katalogów</h3>
              <hr />
              <p>
                Pierwszym katalogiem w którym nowy layout został zastosowany był
                katalog świąteczny, którego grafika miała na celu wywołanie jak
                najmilszych emocji związanych ze świętami oraz zachęcenie do
                zakupu czekoladek jako prezentu dla partnerów biznesowych.
                <br></br>
                <br></br>
                Poza graficznym opracowaniem katalogów doradzaliśmy również w
                zakresie doboru materiałów i uszlachetnień użytych w druku
                offsetowym.
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <img
              src={require("../../assets/img/portfolio/mb_img-1.png")}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid mb_section_4">
        <div className="row">
          <div className="col-md-12">
            <img
              src={require("../../assets/img/portfolio/mb_img-2.png")}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid mb_section_5">
        <div className="row">
          <div className="col-md-12">
            <img
              src={require("../../assets/img/portfolio/mb_img-3.png")}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid mb_section_6">
        <div className="row">
          <div className="col-md-6 offset-md-1">
            <div className="inner">
              <h3>Sesja Zdjęciowa</h3>
              <hr />
              <p>
                Ważnym elementem zlecenia było wykonanie zdjęć produktów,
                zarówno zdjęć packshotowych jak i aranżacyjnych. W studio
                fotograficznym powstały dodatkowo zdjęcia, które można zobaczyć
                w kawiarniach Mount Blanc.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid mb_section_7">
        <div className="row">
          <div className="col-md-12">
            <img
              src={require("../../assets/img/portfolio/mb_img-4.png")}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid mb_section_8">
        <div className="row">
          <div className="col-md-12">
            <img
              src={require("../../assets/img/portfolio/mb_img-5.png")}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default MountBlanc;
